.App {
	background-image: url('/images/frontBg.png');
	display: block;
	height: 615px;
	margin-top: -90px;
	background-position: center;
	background-repeat: no-repeat;
	font-family: 'Roboto Condensed', sans-serif;
}

.container {
	width: 1180px;
	margin: 0 auto;
}

.header-img {
	background-image: url('/images/header.png');
	display: block;
	height: 179px;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	z-index: 1;
	position: relative;
}

.house {
	.houseBackground {
		opacity: 0;
		margin-top: 120px;
		margin-left: -110px;
		position: relative;
		-webkit-transition: opacity 2s ease-out;
		-moz-transition: opacity 2s ease-out;
		-o-transition: opacity 2s ease-out;
		transition: opacity 2s ease-out;
		&.show {
			opacity: 1;
			transition-delay: 2s;
			position: absolute;
		}
	}
	.actions {
		position: absolute;
		.tvOn {
			position: absolute;
			opacity: 0;
			top: 289px;
			left: -25px;
			&.on {
				opacity: 1;
			}
		}
		.fanOn {
			position: absolute;
			opacity: 0;
			top: 174px;
			left: 419px;
			-webkit-transition: opacity 1s ease-out;
			-moz-transition: opacity 1s ease-out;
			-o-transition: opacity 1s ease-out;
			transition: opacity 1s ease-out;
			&.on {
				opacity: 1;
			}
		}
		.klimaOn {
			position: absolute;
			opacity: 0;
			top: 239px;
			left: 313px;
			z-index: 1;
			-webkit-transition: opacity 1s ease-out;
			-moz-transition: opacity 1s ease-out;
			-o-transition: opacity 1s ease-out;
			transition: opacity 1s ease-out;
			&.on {
				opacity: 1;
			}
		}
		.klimaOpen {
			position: absolute;
			opacity: 0;
			top: 209px;
			left: 307px;
			-webkit-transition: opacity 1s ease-out;
			-moz-transition: opacity 1s ease-out;
			-o-transition: opacity 1s ease-out;
			transition: opacity 1s ease-out;
			&.on {
				opacity: 1;
			}
		}
		.contactOn {
			position: absolute;
			opacity: 0;
			top: 287px;
			left: 214px;
			z-index: 1;
			-webkit-transition: opacity 1s ease-out;
			-moz-transition: opacity 1s ease-out;
			-o-transition: opacity 1s ease-out;
			transition: opacity 1s ease-out;
			&.on {
				opacity: 1;
			}
		}
		.ovenOn {
			position: absolute;
			opacity: 0;
			top: 303px;
			left: 211px;
			-webkit-transition: opacity 1s ease-out;
			-moz-transition: opacity 1s ease-out;
			-o-transition: opacity 1s ease-out;
			transition: opacity 1s ease-out;
			&.on {
				opacity: 1;
			}
		}
		.garageOn {
			position: absolute;
			opacity: 1;
			top: 293px;
			left: 691px;
			-webkit-transition: opacity 0.5s ease-out;
			-moz-transition: opacity 0.5s ease-out;
			-o-transition: opacity 0.5s ease-out;
			transition: opacity 0.5s ease-out;
			&.on {
				opacity: 1;
			}
		}
		.garageTop {
			position: absolute;
			opacity: 1;
			top: 293px;
			left: 691px;
			-webkit-transition: opacity 0.5s ease-out;
			-moz-transition: opacity 0.5s ease-out;
			-o-transition: opacity 0.5s ease-out;
			transition: opacity 0.5s ease-out;
			&.on {
				opacity: 1;
			}
		}
		.lampBedroomOn {
			position: absolute;
			opacity: 0;
			top: 138px;
			left: 498px;
			-webkit-transition: opacity 1s ease-out;
			-moz-transition: opacity 1s ease-out;
			-o-transition: opacity 1s ease-out;
			transition: opacity 1s ease-out;
			&.true {
				opacity: 1;
			}
		}
		.lampLivingroomOn {
			position: absolute;
			opacity: 0;
			top: 415px;
			left: 40px;
			-webkit-transition: opacity 1s ease-out;
			-moz-transition: opacity 1s ease-out;
			-o-transition: opacity 1s ease-out;
			transition: opacity 1s ease-out;
			&.true {
				opacity: 1;
			}
		}
		.BlindsGarageOn {
			position: absolute;
			top: 474px;
			left: 748px;
			-webkit-transition: opacity 1s ease-out;
			-moz-transition: opacity 1s ease-out;
			-o-transition: opacity 1s ease-out;
			transition: opacity 1s ease-out;
			&.true {
				opacity: 1;
			}
		}
		.BlindsLivingroomOn {
			position: absolute;
			top: 497px;
			left: 433px;
			-webkit-transition: opacity 1s ease-out;
			-moz-transition: opacity 1s ease-out;
			-o-transition: opacity 1s ease-out;
			transition: opacity 1s ease-out;
		}
	}
}

.mobile {
	width: 220px;
	height: 390px;
	display: inline-block;
	position: absolute;
	margin-left: 910px;
	margin-top: 225px;
	overflow: hidden;
	.bannerDefault {
		position: relative;
		margin-top: 400px;
		-webkit-transition: margin-top 1s ease-out;
		-moz-transition: margin-top 1s ease-out;
		-o-transition: margin-top 1s ease-out;
		transition: margin-top 1s ease-out;
		&.show {
			margin-top: 0px;
			transition-delay: 4s;
		}
	}
}

.slogan {
	position: absolute;
	color: #fff;
	float: right;
	margin-left: 812px;
	margin-top: -154px;
	padding: 0;
	font-weight: normal;
	max-width: 402px;
	text-align: center;
	font-size: 48px;
	-webkit-transition: margin-top 1s ease-out;
	-moz-transition: margin-top 1s ease-out;
	-o-transition: margin-top 1s ease-out;
	transition: margin-top 1s ease-out;
	p {
		margin: 0;
		&:last-child {
			font-size: 24px;
		}
	}
	&.show {
		margin-top: 85px;
		transition-delay: 4s;
	}
}

.menu {
	-webkit-transition: opacity 1s ease-out;
	-moz-transition: opacity 1s ease-out;
	-o-transition: opacity 1s ease-out;
	transition: opacity 1s ease-out;
	position: relative;
	color: #fff;
	top: 0;
	margin-top: -358px;
	&.show {
		opacity: 1;
		transition-delay: 5.5s;
	}
	span {
		display: block;
		padding-left: 22px;
		&:nth-child(2) {
			font-size: 13px;
			margin-top: 4px;
		}
	}
	.bannerMenu {
		margin: 0;
		padding-left: 11px;
	}
	.slider-point {
		background-image: url('/images/slider-point.png');
		width: 206px;
		height: 10px;
		margin-left: 8px;
		margin-top: -4px;
	}
	.btns {
		cursor: pointer;
		margin-top: 0;
		margin-left: 11px;
		.bigBtn {
			background-image: url('/images/buttons/bigBtn.png');
			width: 100px;
			height: 63px;
			display: inline-block;
			&.on {
				background-image: url('/images/buttons/bigBtnOn.png');
				width: 100px;
				height: 63px;
			}
			.icon {
				margin: 9px 13px;
			}
		}
		.miniBtn {
			background-image: url('/images/buttons/miniBtn.png');
			width: 65px;
			height: 69px;
			margin-top: -4px;
			display: inline-block;
			vertical-align: top;
			&.on {
				background-image: url('/images/buttons/miniBtnOn.png');
				width: 65px;
				height: 69px;
				margin-top: -4px;
			}
			.icon {
				margin: 17px 8px;
			}
		}
		.longBtn {
			background-image: url('/images/buttons/longBtn.png');
			width: 96px;
			height: 54px;
			display: inline-block;
			&.longBtnOn {
				background-image: url('/images/buttons/longBtnOn.png');
				width: 96px;
				height: 54px;
			}
			.icon {
				margin: 20px 17px;
			}
		}
		.bigBtn,
		.longBtn {
			vertical-align: top;
		}
		.longBtn {
			margin-top: -12px;
			width: 96px;
		}
		div {
			display: inline-block;
		}
		.bigBtnLong {
			background-image: url('/images/buttons/bigBtnLong.png');
			width: 190px;
			height: 62px;
			margin-left: 4px;
			margin-bottom: -7px;
			margin-top: -6px;
			.icon {
				padding-top: 18px;
				padding-left: 16px;
				display: block;
			}
			display: inline-block;
			&.on {
				background-image: url('/images/buttons/bigBtnLongOn.png');
			}
		}
		.uninstall {
			margin-left: 5px;
			cursor: no-drop;
		}
		.disabled {
			cursor: no-drop;
			.seeAll {
				background-image: url('/images/buttons/see-all.png');
				width: 94px;
				height: 40px;
				position: absolute;
				background-repeat: no-repeat;
				margin-top: -16px;
				margin-left: 7px;
			}
		}
	}
	.bannerMenu {
		width: 200px;
		height: 74px;
		margin-left: 10px;
		&.default {
			background-image: url('/images/bannerDefault.png');
		}
		&.klima {
			background-image: url('/images/banner/klima.png');
		}
		&.lamp {
			background-image: url('/images/banner/lamp.png');
		}
		&.tv {
			background-image: url('/images/banner/tv.png');
		}
		&.fan {
			background-image: url('/images/banner/vent.png');
		}
		&.blinds {
			background-image: url('/images/banner/blinds.png');
		}
		&.contact {
			background-image: url('/images/banner/contact.png');
		}
		&.garage {
			background-image: url('/images/banner/garage.png');
		}
		&.camera {
			background-image: url('/images/banner/camera.png');
		}
	}
	.btnBack {
		cursor: pointer;
		.iconBack {
			background-image: url('/images/icons/back.png');
			width: 7px;
			height: 16px;
			padding: 0;
			display: inline-block;
			margin-left: 23px;
			margin-right: 7px;
			vertical-align: -2px;
		}
	}
	.blinds {
		img {
			padding-top: 24px!important;
			padding-left: 20px!important;
		}
		.uninstall {
			padding-top: 0 !important;
			padding-left: 0 !important;
		}
	}
	&.camera {
		.left {
			position: absolute;
			top: 46%;
			left: 0;
			border: unset;
			background: none;
			width: 50px;
			.icon {
				background-image: url('/images/btnCameraLeft.png');
				width: 36px;
				height: 36px;
				background-repeat: no-repeat;
				opacity: 0.5;
				&:hover {
					opacity: 1;
				}
			}
			&.true {
				display: block;
			}
			&.false {
				display: none;
			}
		}
		.right {
			position: absolute;
			top: 46%;
			right: 0;
			border: unset;
			background: none;
			width: 50px;
			.icon {
				background-image: url('/images/btnCameraRight.png');
				width: 36px;
				height: 36px;
				background-repeat: no-repeat;
				opacity: 0.5;
				&:hover {
					opacity: 1;
				}
			}
			&.true {
				display: block;
			}
			&.false {
				display: none;
			}
		}
		span {
			position: relative;
			z-index: 1;
			text-shadow: 0px 0px 5px black;
		}
		.textBack {
			display: inline-block;
			padding: 0;
			font-size: 16px;
			margin-top: 0;
			position: absolute;
		}
	}
}

.actions {
	opacity: 0;
	-webkit-transition: opacity 2s ease-out;
	-moz-transition: opacity 2s ease-out;
	-o-transition: opacity 2s ease-out;
	transition: opacity 2s ease-out;
	&.show {
		display: block;
		opacity: 1;
		transition-delay: 2s;
	}
}

.containerBgBlack {
	margin-left: -410px;
	.bgBlack {
		background-image: url('/images/bgBlack.png');
		background-repeat: no-repeat;
		background-position: bottom;
		height: 320px;
		width: 2000px;
		position: fixed;
		z-index: -1;
		bottom: 0;
	}
}

.parentCameraBox {
	background-image: url('/images/bgCamera.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	height: 390px;
	width: 480px;
	margin-top: -68px;
	left: -260px;
	position: relative;
	-webkit-transition: margin-left 0.5s ease-out;
	-moz-transition: margin-left 0.5s ease-out;
	-o-transition: margin-left 0.5s ease-out;
	transition: margin-left 0.5s ease-out;
}

.bgCameraKitchen {
	background-image: url('/images/camera-phone.png');
	background-repeat: no-repeat;
	height: 393px;
	width: 480px;
	margin-top: -416px;
	position: fixed;
}